import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { notifications } from "../notifications";
import { sessionDataHandler } from "../sessionDataHandler";
import { removeNotification, updateConversationEnded } from "../store/actions/genericActions";
import { initSession } from "../store/actions/initActions";
import { AppState } from "../store/definitions";
import { AttachFileDropArea } from "./AttachFileDropArea";
import { MessageInput } from "./MessageInput";
import { MessageList } from "./MessageList";
import { NotificationBar } from "./NotificationBar";
import { ConversationEnded } from "./ConversationEnded";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const conversationState = useSelector((state: AppState) => state.chat.conversationState);
    const { sessionData } = useSelector((state: AppState) => state.config) || {};
    const conversationEnded = useSelector((state: AppState) => state.session.conversationEnded);

    const createSession = async () => {
        const data = await sessionDataHandler.fetchAndStoreNewSession({
            formData: {
                friendlyName: sessionData?.nome,
                ...sessionData
            }
        });
        dispatch(initSession({ token: data.token, conversationSid: data.conversationSid }));
    };

    useEffect(() => {
        createSession().catch(console.error);
    }, []);

    useEffect(() => {
        if (!sessionDataHandler.tryResumeExistingSession() && conversationEnded && conversationState === "closed") {
            createSession().catch(console.error);
            dispatch(updateConversationEnded(false))
        }
    }, [conversationState, conversationEnded]);

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
    }, [dispatch]);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    return (
        <Wrapper>
            {/* <Header /> */}
            <NotificationBar />
            <MessageList />
            {conversationState === "active" ? <MessageInput /> : <ConversationEnded />}
        </Wrapper>
    );
};
