import merge from "lodash.merge";
import { render } from "react-dom";
import { Provider } from "react-redux";

import { WebchatWidget } from "./components/WebchatWidget";
import { initLogger } from "./logger";
import { sessionDataHandler } from "./sessionDataHandler";
import { initConfig } from "./store/actions/initActions";
import { ConfigState } from "./store/definitions";
import { store } from "./store/store";

const getDomain = () => {
    if (
        window.location.hostname !== "localhost" &&
        document.currentScript &&
        (document.currentScript as HTMLScriptElement).src
    ) {
        const uri = new URL((document.currentScript as HTMLScriptElement).src);
        return uri.origin;
    }
    return process.env.REACT_APP_LOCAL_SERVER_URL;
};

const generateDefaultConfig = (): ConfigState => {
    const urlParams = new URLSearchParams(window.location.search);
    const accountSid = urlParams.get("accountSid") || "";
    const flexFlowSid = urlParams.get("flexFlowSid") || "";
    const matricula = urlParams.get("matricula") || "";
    const nome = urlParams.get("nome") || "";
    const isMobile = urlParams.get("mobile") === "true";

    return {
        serverUrl: getDomain(),
        sessionData: {
            accountSid,
            flexFlowSid,
            matricula,
            nome,
            origin: "Chat",
            location: window.location.href,
            isMobile: isMobile.toString()
        },
        theme: {
            isLight: true
        },
        fileAttachment: {
            enabled: false,
            maxFileSize: 16777216, // 16 MB
            acceptedExtensions: ["jpg", "jpeg", "png", "amr", "mp3", "mp4", "pdf", "txt"]
        }
    };
};

const initWebchat = async (config: ConfigState) => {
    const defaultConfig = generateDefaultConfig();
    const mergedConfig = merge({}, defaultConfig, config);
    sessionDataHandler.setEndpoint(mergedConfig.serverUrl);
    store.dispatch(initConfig(mergedConfig));
    initLogger();
    const rootElement = document.getElementById("twilio-webchat-widget-root");

    render(
        defaultConfig.sessionData?.isMobile === "true" ? (
            <Provider store={store}>
                <WebchatWidget />
            </Provider>
        ) : (
            <></>
        ),
        rootElement
    );

    if (window.Cypress) {
        window.store = store;
    }
};

declare global {
    interface Window {
        Twilio: {
            initWebchat: (config: ConfigState) => void;
        };
        Cypress: Cypress.Cypress;
        store: typeof store;
    }
}

// Expose `initWebchat` function to window object
Object.assign(window, {
    Twilio: {
        initWebchat
    }
});
