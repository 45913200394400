import { useSelector } from "react-redux";

import { AppState, EngagementPhase } from "../store/definitions";
import { MessagingCanvasPhase } from "./MessagingCanvasPhase";
import { LoadingPhase } from "./LoadingPhase";

const getPhaseComponent = (phase: EngagementPhase) => {
    switch (phase) {
        case EngagementPhase.Loading:
            return <LoadingPhase />;
        case EngagementPhase.MessagingCanvas:
            return <MessagingCanvasPhase />;
        default:
            return <MessagingCanvasPhase />;
    }
};

export function RootContainer() {
    const { currentPhase } = useSelector(({ session }: AppState) => ({
        currentPhase: session.currentPhase,
        expanded: session.expanded
    }));

    return getPhaseComponent(currentPhase);
}
